import t, { trans } from '../../translations/translate'
import i18next from 'i18next'
import { Instagram, YouTube, Facebook } from '@mui/icons-material'


export const Footer = () => {
  const currentLanguage = i18next.language

  const legalItems = [
    { path: `https://www.canaryshuttle.com/${currentLanguage}/general-info`, label: 'legal.general_info' },
    { path: `https://www.canaryshuttle.com/legal/legal-info-${currentLanguage}.pdf`, label: 'legal.legal_info' },
    { path: `https://www.canaryshuttle.com/legal/cookies-policy-${currentLanguage}.pdf`, label: 'legal.cookies_policy' },
    { path: `https://www.canaryshuttle.com/legal/general-terms-and-conditions-${currentLanguage}.pdf`, label: 'legal.terms_and_conditions' },
    { path: `https://www.canaryshuttle.com/legal/privacy-policy-${currentLanguage}.pdf`, label: 'legal.privacy_policy' },
    { path: `https://www.canaryshuttle.com/${currentLanguage}/complains`, label: 'legal.complaints_and_claims' },
    { path: `https://www.canaryshuttle.com/${currentLanguage}/lost-and-founds`, label: 'footer.lost_and_found_goods' }
  ]
  const helpItems = [
    { path: `https://www.canaryshuttle.com/${currentLanguage}/how-to-book`, label: 'footer.how_to_book' },
    { path: `https://www.canaryshuttle.com/${currentLanguage}`, label: 'footer.manage_booking' }
  ]

  const contactLinks = [
    { path: 'mailto:reservas.canarias@alsa.es', label: 'reservas.canarias@alsa.es' },
    { path: `https://www.canaryshuttle.com/${currentLanguage}`, label: 'www.canaryshuttle.com' }
  ]

  return (
    <footer
      className="mt-8 flex w-[100%] flex-col items-center justify-around gap-5 bg-[#333333] text-white md:px-44"
      role="contentinfo"
      aria-label={trans('aria_label.footer')}>
      <div className="flex w-full flex-col flex-wrap items-start justify-between text-left lg:flex-row py-10">
        <span className="flex w-full flex-col gap-2 px-4 text-sm lg:w-1/3">
          <p className="text-xl text-primary-yellow">{t('legal.title')}</p>
          <ul>
          {legalItems.map((link, index) => (
            <li key={index}>
              <a
                href={link.path}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                {t(link.label)}
              </a>
            </li>
          ))}

          </ul>
        </span>
        <span className="flex w-full flex-col gap-2 px-4 text-sm lg:w-1/3">
          <p className="text-xl text-primary-yellow">{t('footer.help')}</p>
          <ul>
          {helpItems.map((link, index) => (
            <li key={index}>
              <a
                href={link.path}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                {t(link.label)}
              </a>
            </li>
          ))}
          </ul>
        </span>
        <span className="flex w-full flex-col gap-2 px-4 text-sm lg:w-1/3">
          <p className="text-xl text-primary-yellow">{t('contact')}</p>
          <ul>
            <li>
                Movilidad Turística Canaria S.A.U, Calle Adelfas 32,
                <br />
                Las Palmas, España, 35118
            </li>
            <li>
              +34 928 184 608 / +34 650 617 296
            </li>
            {contactLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.path}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  {t(link.label)}
                </a>
              </li>
            ))}
            <li>
              <a href="https://www.instagram.com/canaryshuttle/" target="_blank" rel="noreferrer">
                <Instagram />
              </a>
              <a href="https://www.facebook.com/canaryshuttle/" target="_blank" rel="noreferrer">
                <Facebook />
              </a>
              <a href="https://www.youtube.com/@Grupo_1844" target="_blank" rel="noreferrer">
                <YouTube />
              </a>
            </li>
          </ul>
        </span>
      </div>
      <div>
        <img src="/images/footer.png" alt="financiancion" className='w-[40vw]'/>
      </div>
    </footer>
  )
}
