export const en = {
  book_now: 'Book your transfer to the Airport',
  buy: 'Buy',
  distance_km: 'Distance (km)',
  island: 'Island',
  loading_transfers: 'Loading transfers...',
  no_transfers_found: 'No transfers found on your location',
  price_per_pax: '{{price}} /pax',
  search: 'Search',
  select_service: 'Choose',
  transfers: 'Transfers',
  transfers_other: '{{count}} Transfers near you',
  where_are_you: 'Where are you?',
  contact_us: 'Contact us',
  contact: 'Contact',
  try_again: 'Try again',
  close: 'Close',
  service_selector: 'Select service',
  use_my_current_location: 'Use my current location',
  location: 'Location',
  enter_your_location: 'Enter your location',
  home: {
    title: 'Airport transfers in the Canary Islands with <span className="font-bold text-primary-orange">Last</span><span className="font-bold text-primary-gray">Minute</span>',
    subtitle: 'Available in Tenerife, Gran Canaria, Lanzarote and Fuerteventura. Book your transfer to the airport in advance and save time and money. Enjoy your trip without worries.',
    book_now: 'Book now!',
    lm_description: {
      title: 'How does it work?',
      title_1: 'Easily book your bus seat',
      description_1: 'With LastMinute, you can book a seat on a bus that passes through your area in the next 72 hours. It\'s that easy! No more complications to reach your destination airport.',
      title_2: 'Find the closest stop to you',
      description_2: 'Just search for the area, time, and distance to see the stops that a bus will pass through. You don\'t have to worry about how to get to the airport anymore!',
      title_3: 'Fill out your details and make the payment online',
      description_3: 'Filling out the form with your information is simple and fast. You can also make the payment online securely. You will receive a confirmation email to your email address so you can be sure that your booking was successful.',
      title_4: 'Comfort and peace of mind on your journey',
      description_4: 'With LastMinute, you can enjoy a comfortable and peaceful journey to the airport on the island. Let us take care of the transportation!',
      title_5: 'Join the CanaryShuttle traveler community',
      description_5: 'Join the community of travelers who trust CanaryShuttle to reach their destination comfortably and securely. Discover why we are the best option for your transportation!'
    }
  },
  buttons: {
    home: 'Home'
  },
  filters: {
    title: 'Filters',
    transfer_date: 'Transfer date',
    transfer_time: 'Transfer time',
    radius: 'Search radius (km)',
    address: 'Address',
    radius_info: 'We will search for the transfers closest to your location, but you can set a different search radius.',
    please_select_a_location: 'Please, select a location',
    please_select_a_valid_location: 'Please, select a valid location'
  },
  booking_form: {
    title: 'Booking form',
    error_saving_booking: 'An error occurred while saving the booking',
    contact: {
      title: 'Contact details',
      name: 'Name',
      email: 'Email',
      email_confirmation: 'Confirm email',
      phone: 'Phone',
      contact_details_info: 'Please fill in the following fields with your contact details.',
      user_emails: 'User email',
      other_email: 'Other:'
    },
    validations: {
      email_error: 'You must enter a valid email',
      name_error: 'You must enter a valid name',
      email_confirmation_error: 'The confirmation email does not match',
      phone_error: 'You must enter a valid phone number'
    },
    pax: {
      title: 'Who is travelling with you?',
      adults: 'Adults',
      adults_info: 'Over 12 years old',
      babies: 'Babies',
      babies_info: 'From 0 to 3 years old',
      children: 'Children',
      children_info: 'From 3 to 12 years old'
    },
    extra: {
      title: 'Extra luggage',
      surf_board: 'Surf board',
      bicycles: 'Bicycle',
      baby_seat: 'Baby seat',
      golf: 'Golf',
      special: 'Special',
      no_extra_luggage_for_this_service: 'There are no extra luggage options available for this service.'
    },
    transfer_info: {
      title: 'Transfer information',
      date: '<strong>Date:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{date}}</span>',
      end_place: '<strong>Destination:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{end_place}}</span>',
      end_time: '<span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{end_time}}</span>',
      island: '<strong>Island:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{island}}</span>',
      start_place: '<strong>Pick up place:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{start_place}}</span>',
      start_time: '<span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{start_time}}</span>'
    },
    agency: {
      title: 'Agency',
      external_booking_id: 'External booking ID',
      external_booking_id_info: 'You can enter an external booking ID that identifies this booking in your system.'
    }
  },
  payment_error: {
    title: 'Payment error',
    message: 'Sorry, but it seems that there was a problem processing your payment. Please try again or select another payment method. If the problem persists, please do not hesitate to contact our customer service team for assistance.'
  },
  payment_success: {
    title: 'My booking',
    sub_title: 'Transfer confirmed!',
    welcome: 'Hello <strong>{{name}}</strong><br />Thank you for choosing Canary Shuttle.<br />Please see the details of your transfer below.',
    start_time: '<span className="font-bold">Pick-up time</span>: <span className="text-primary-orange">{{start_time}}</span>',
    start_date: '<span className="font-bold">Pick-up date</span>: <span className="text-primary-orange">{{start_date}}</span>',
    pickup_place: '<span className="font-bold">Pick-up place</span>',
    place: '<span className="font-bold">Place</span>: <span className="text-primary-orange">{{place}}</span>',
    coordinates: '<span className="font-bold">Coordinates</span>:',
    passengers: '<span className="font-bold">Passengers</span>',
    adults: '<span className="font-bold">Adults</span>: <span className="text-primary-orange">{{adults}}</span>',
    children: '<span className="font-bold">Children</span>: <span className="text-primary-orange">{{children}}</span>',
    infants: '<span className="font-bold">Infants</span>: <span className="text-primary-orange">{{infants}}</span>',
    destination: '<span className="font-bold">Destination</span>',
    booking_not_found: 'Oops! Booking not found',
    special_baggage: '<span className="font-bold">Special baggage</span>',
    baby_seats: '<span className="font-bold">Baby seats</span>: <span className="text-primary-orange">{{baby_seats}}</span>',
    surf_table: '<span className="font-bold">Surf table</span>: <span className="text-primary-orange">{{surf_table}}</span>',
    golf_bag: '<span className="font-bold">Golf bag</span>: <span className="text-primary-orange">{{golf_bag}}</span>',
    bike: '<span className="font-bold">Bike</span>: <span className="text-primary-orange">{{bike}}</span>',
    special: '<span className="font-bold">Special</span>: <span className="text-primary-orange">{{special}}</span>',
    booking_id: '<span className="font-bold">Booking ID</span>: <span className="text-primary-orange"># {{booking_id}}</span>',
    amount: '<span className="font-bold">Amount</span>: <span className="text-primary-orange">€ {{amount}}</span>',
    loading: 'Loading...'
  },
  search_booking: {
    title: 'Search booking',
    booking_id: 'Booking number',
    booking_id_placeholder: '104723',
    booking_id_helper_text: 'You can find the booking number in your confirmation email',
    booking_date: 'Pick-up date',
    booking_date_error: 'Please select a valid date'
  },
  index: {
    about_us: 'About us',
    about_us_description: 'LastMinute</span> is an application of the company <span className="font-bold">CanaryShuttle</span>, specialized in <span className="font-bold">bus transfers</span> in the Canary Islands. With <span className="font-bold">LastMinute</span>, you can make <span className="font-bold">booking transfers</span> with just 72 hours in advance, offering you a <span className="font-bold">easy and convenient booking</span> experience.'
  },
  service_card: {
    meeting_point: '<span className="font-bold min-w-fit">Meeting point</span>:<span className="min-w-fit text-slate-800 ml-1 text-ellipsis">{{meeting_point}}</span>',
    available_pax: '<span className="font-bold min-w-fit">Available seats</span>:<span className="min-w-fit text-slate-800 ml-1">{{count}}</span>',
    start_time: '<span className="font-bold min-w-fit">Departure time</span>:<span className="min-w-fit text-slate-800 ml-1">{{start_time}}</span>',
    end_time: '<span className="font-bold min-w-fit">Arrival time</span>:<span className="min-w-fit text-slate-800 ml-1">{{end_time}}</span>',
    date: '<span className="font-bold min-w-fit">Date</span>:<span className="min-w-fit text-slate-800 ml-1">{{date}}</span>',
    distance: '{{distance}} km from your location',
    price_per_pax: '<span className="font-bold min-w-fit">Price</span>:<span className="min-w-fit text-slate-800 ml-1">{{price_per_pax}}/pax</span>'
  },
  auth: {
    title: 'Login in <span className="font-bold text-primary-orange">Last</span><span className="font-bold text-primary-gray">Minute</span>',
    login_failed: 'Login failed',
    login_error: 'Login failed. Username or passcode incorrect',
    passcode_placeholder: 'Enter your passcode',
    username_placeholder: 'Enter your username',
    username: 'Username',
    passcode: 'Passcode',
    login: 'Login',
    logout: 'Logout'
  },
  my_bookings: {
    title: 'My Bookings',
    no_bookings: 'You have no bookings',
    no_more_bookings: 'There are no more bookings',
    filters: {
      title: 'Filters',
      booking_id: 'Booking ID',
      start_date: 'Transfer date (from)',
      end_date: 'Transfer date (to)',
      results_per_page: 'Number of results',
      filter: 'Filter',
      clear: 'Clear'
    },
    table: {
      booking_date: 'Transfer date',
      booking_id: 'ID',
      booking_paxnames: 'Passengers',
      booking_island: 'Island',
      booking_start_place: 'Pickup',
      booking_start_time: 'Departure',
      booking_end_time: 'Arrival',
      booking_end_place: 'Destination',
      booking_price: 'Price',
      booking_pax_names: 'Passengers',
      booking_pax_children: 'Children',
      booking_pax_adults: 'Adults',
      booking_pax_infants: 'Infants',
      booking_pax_total: 'Total passengers',
      booking_special: 'Special luggage',
      booking_baby_seats: 'Baby seats',
      booking_surf_table: 'Surfboard',
      booking_golf_bag: 'Golf bag',
      booking_bike: 'Bike',
      details: 'Details'
    }
  },
  services_list: {
    title: 'Available transfers',
    no_results: 'No transfers found',
    sort_by_distance: 'Sort by distance',
    sort_by_departure_time: 'Sort by departure time',
    no_services_found: 'No transfers found',
    loading: 'Loading...'
  },
  cookie_disclaimer: {
    title: 'Your privacy is important to us',
    description: 'We use cookies to improve your experience on this website. By continuing to browse, you accept the use of cookies. You can change your cookie settings at any time.',
    configure: 'Cookie Settings',
    necessary: 'Necessary',
    necessary_description: 'Necessary for the basic functioning of the website. Some examples include: session cookies necessary to transmit the website, authentication cookies and security cookies.',
    preferences: 'Preferences',
    preferences_description: 'Allow to remember information that changes the appearance or behavior of the website, such as your preferred language or the region where you are.',
    personalize: 'Customize Cookies',
    continue: 'Continue',
    accept_all: 'Accept all'
  },
  legal: {
    title: 'Legal',
    general_info: 'General Information',
    terms_and_conditions: 'General terms and conditions',
    privacy_policy: 'Privacy Policy',
    cookies_policy: 'Cookies Policy',
    complaints_and_claims: 'Complaints and claims',
    legal_info: 'Legal Information legal'
  },
  aria_label: {
    main_menu: {
      home: 'Go to home page',
      search_booking: 'Search booking'
    },
    header: 'Application header',
    footer: 'Application footer',
    view_location: 'Link to view location on Google Maps',
    loading: 'Loading...',
    change_language_to_spanish: 'Change language to Spanish',
    change_language_to_english: 'Change language to English',
    next_image: 'Next image',
    previous_image: 'Previous image',
    expand_filters: 'Expand/Collapse search filters',
    expand_extra_options: 'Expand/Collapse extra options',
    sort_options: 'Sort options',
    sort_by_distance: 'Sort by distance',
    sort_by_departure_time: 'Sort by departure time',
    decrease_quantity: 'Decrease quantity',
    increase_quantity: 'Increase quantity',
    increase_radius: 'Increase radius',
    decrease_radius: 'Decrease radius',
    menu_button: 'Open/Close user menu',
    user_icon: 'User icon',
    logout_button: 'Button to logout',
    logout_icon: 'Logout icon',
    my_bookings_link: 'Link to my bookings',
    close_button: 'Close button',
    contact_us_modal_description: 'Modal to show contact information for Canary Tourist Shuttle',
    cookie_disclaimer: 'Cookie disclaimer',
    cookie_disclaimer_button_custom: 'Button to accept custom cookies',
    cookie_disclaimer_button_all: 'Button to accept all cookies',
    cookie_disclaimer_personalize: 'Button to customize cookies',
    booking_id_helper_text: 'Helper text to show booking number'
  },
  footer: {
    information: 'Information',
    help: 'Help',
    contact: 'Contact',
    how_to_book: 'How to book?',
    manage_booking: 'Manage my booking',
    lost_and_found_goods: 'Lost and Found Goods'
  }
}
